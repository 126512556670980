<template>
  <app-form-view
    app="product"
    model="masterproduct"
    api-url="product/master-product/"
    :title="$t('menu.masterProduct')"
    :state="formData.state"
    :stateItems="stateItems"
    :approveItems="approveItems"
    v-model="formData"
  >
    <template v-slot="{ view }">
      <v-row>
        <v-col cols="12" lg="6" class="app-input--approve-wrapper">
          <app-input
            name="name"
            type="text"
            rules="required"
            :label="$t('fields.master_product_name')"
            :view="view"
            v-model="formData.name"
          />
          <app-input
            v-if="isSentToApprove"
            name="approveMasterProductName"
            type="switch"
            class="approve"
            :label="$t('fields.approve')"
            v-model="formData.approve_master_product_name"
          />
        </v-col>
        <v-col cols="12" lg="6" class="app-input--approve-wrapper">
          <app-input
            name="code"
            type="text"
            rules="required"
            :label="$t('fields.master_product_code')"
            :view="view"
            v-model="formData.code"
          />
          <app-input
            v-if="isSentToApprove"
            name="approveMasterProductCode"
            type="switch"
            class="approve"
            :label="$t('fields.approve')"
            v-model="formData.approve_master_product_code"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" lg="6" class="app-input--approve-wrapper">
          <app-input
            name="description"
            type="text"
            :label="$t('fields.master_product_description')"
            :view="view"
            v-model="formData.description"
          />
          <app-input
            v-if="isSentToApprove"
            name="approveMasterProductDescription"
            type="switch"
            class="approve"
            :label="$t('fields.approve')"
            v-model="formData.approve_master_product_description"
          />
        </v-col>
        <v-col cols="12" lg="6" class="app-input--approve-wrapper">
          <app-input
            name="price"
            type="number"
            rules="required"
            :label="$t('fields.master_product_price')"
            :view="view"
            v-model="formData.master_product_price"
          />
          <app-input
            v-if="isSentToApprove"
            name="approveMasterProductPrice"
            type="switch"
            class="approve"
            :label="$t('fields.approve')"
            v-model="formData.approve_master_product_price"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" lg="6" class="app-input--approve-wrapper">
          <app-input
            name="whtRate"
            type="number"
            rules="required|between:0,100"
            :label="$t('fields.wht')"
            :view="view"
            v-model="formData.whtRate"
          />
          <app-input
            v-if="isSentToApprove"
            name="approveMasterProductTax"
            type="switch"
            class="approve"
            :label="$t('fields.approve')"
            v-model="formData.approve_master_product_whtRate"
          />
        </v-col>
        <v-col cols="12" lg="6" class="app-input--approve-wrapper">
          <app-input
            name="isAuditPeriod"
            type="switch"
            :label="$t('fields.isAuditPeriod')"
            :view="view"
            v-model="formData.is_audit_period"
          />
          <app-input
            v-if="isSentToApprove"
            name="approveMasterProductIsAuditPeriod"
            type="switch"
            class="approve"
            :label="$t('fields.approve')"
            v-model="formData.approve_master_product_is_audit_period"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" lg="6" class="app-input--approve-wrapper">
          <app-input
            name="vat"
            type="switch"
            :label="$t('fields.vat')"
            :view="view"
            v-model="formData.vat"
          />
          <app-input
            v-if="isSentToApprove"
            name="approveMasterProductVat"
            type="switch"
            class="approve"
            :label="$t('fields.approve')"
            v-model="formData.approve_master_product_vat"
          />
        </v-col>
        <v-col
          cols="12"
          lg="6"
          class="app-input--approve-wrapper"
          v-if="formData.vat"
        >
          <app-input
            name="vatRate"
            type="number"
            rules="required|between:0,100"
            :label="$t('fields.vatRate')"
            :view="view"
            v-model="formData.vatRate"
          />
          <app-input
            v-if="isSentToApprove"
            name="approveProductvatRate"
            type="switch"
            class="approve"
            :label="$t('fields.approve')"
            v-model="formData.approve_master_product_vatRate"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" lg="6" class="app-input--approve-wrapper">
          <app-input
            name="active"
            type="switch"
            :label="$t('fields.active')"
            :view="view"
            v-model="formData.active"
          />
          <app-input
            v-if="isSentToApprove"
            name="approveMasterProductActive"
            type="switch"
            class="approve"
            :label="$t('fields.approve')"
            v-model="formData.approve_master_product_active"
          />
        </v-col>
      </v-row>
    </template>
  </app-form-view>
</template>

<script>
import AppInput from '@components/AppInput'
import AppFormView from '@components/AppFormView'

export default {
  name: 'master-product-form',
  components: {
    AppInput,
    AppFormView
  },
  data() {
    return {
      formData: {
        state: 'draft'
      },
      approveItems: [
        'approve_master_product_name',
        'approve_master_product_code',
        'approve_master_product_description',
        'approve_master_product_price',
        'approve_master_product_whtRate',
        'approve_master_product_is_audit_period',
        'approve_master_product_vat',
        'approve_master_product_active',
        'approve_master_product_vatRate'
      ]
    }
  },

  computed: {
    stateItems() {
      return [
        { label: this.$t('label.draft'), value: 'draft' },
        { label: this.$t('label.sentApprove'), value: 'sent_to_approve' },
        { label: this.$t('label.approve'), value: 'approved' }
      ]
    },
    isSentToApprove() {
      return this.formData.state === 'sent_to_approve'
    }
  }
}
</script>
